export const PRODUCT_TILE_1_IMAGE_HEIGHT = 115;
export const PRODUCT_TILE_1_IMAGE_WIDTH = 184;
export const PRODUCT_TILE_1_IMAGE_HEIGHT_MOBILE = 148;
export const PRODUCT_TILE_1_IMAGE_WIDTH_MOBILE = 248;
export const PRODUCT_TILE_2_IMAGE_HEIGHT = 131;
export const PRODUCT_TILE_2_IMAGE_WIDTH = 216;
export const PRODUCT_TILE_2_IMAGE_HEIGHT_MOBILE = 165;
export const PRODUCT_TILE_2_IMAGE_WIDTH_MOBILE = 279;
export const CHECKOUT_SUMMARY_IMAGE_HEIGHT = 144;
export const CHECKOUT_SUMMARY_IMAGE_WIDTH = 86.5;
export const CHECKOUT_SUMMARY_IMAGE_HEIGHT_MOBILE = 60;
export const CHECKOUT_SUMMARY_IMAGE_WIDTH_MOBILE = 100;
// Taken from Mozilla docs.
// 60 is the character limit for email address. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
export const EMAIL_ADDRESS_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const MAX_CHARS_FOR_NAME = 50;
// Quick fix - comes from https://stackoverflow.com/a/44317754. This is just to limit input fields with email address on them
export const MAX_CHARS_FOR_EMAIL = 60;
export const MAX_CHARS_FOR_PHONE_NUMBER = 15;

export enum LOCAL_STORAGE_FEATURES {
  Cars = 'cars',
}
