'use client';
import { useHermes } from '@/auth/use-hermes';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { queryKeys } from '@/queries/query-factory';
import {
  GiftCardCampaignsById,
  giftCardCampaignsResponseSchema,
} from '@/schema/campaigns/campaign.schema';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { useQuery } from '@tanstack/react-query';

export function useGiftCardCampaignsById<T>({
  giftCardIds = [],
  enabled = true,
  select,
}: {
  giftCardIds?: string[];
  enabled?: boolean;
  select?: (data: GiftCardCampaignsById) => T;
}) {
  const baseUrl = getClientUrl();
  const authedFetch = useAuthFetchKy;
  const { loggedIn } = useHermes();

  const queryParams = new URLSearchParams();

  for (const id of giftCardIds) {
    queryParams.append('gift_card_ids[]', id);
  }

  const path = `${baseUrl}/api/campaigns/gift_cards?${queryParams.toString()}`;

  return useQuery({
    ...queryKeys.giftCardCampaigns.list(
      Object.fromEntries(queryParams.entries()),
    ),
    queryFn: async () => {
      const data = await authedFetch.get(path).json();

      const validatedData = validateSchema({
        schemaName: 'gift_card_campaigns_by_id',
        data,
        zodSchema: giftCardCampaignsResponseSchema,
      });

      let giftCardCampaignsById = {} as GiftCardCampaignsById;

      for (const campaignMappings of validatedData) {
        giftCardCampaignsById[campaignMappings.giftCardId] =
          campaignMappings.campaigns;
      }

      return giftCardCampaignsById;
    },
    select,
    enabled: enabled && loggedIn,
  });
}
