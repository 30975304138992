import { z } from 'zod';

export const campaignAppliedDenominationSchema = z.object({
  discountType: z.enum(['multiplier', 'lumpsum']),
  denomination: z.number(),
  discountPercentage: z.number().optional(),
  discountAmount: z.number().optional(),
});

export type CampaignAppliedDenominationSchema = z.infer<
  typeof campaignAppliedDenominationSchema
>;

export const giftCardCampaignSchema = z.object({
  id: z.string(),
  code: z.string(),
  status: z.string(),
  type: z.literal('gift_card_campaign'),
  ranking: z.number(),
  contentDescription: z.string(),
  contentTermsAndConditions: z.string(),
  qualifyingEndTime: z.string().nullable(),
  qualificationType: z.string(),
  appliedDenominations: z.array(campaignAppliedDenominationSchema),
});

export type GiftCardCampaignSchema = z.infer<typeof giftCardCampaignSchema>;

export const giftCardCampaignsById = z.record(
  z.string(),
  z.array(giftCardCampaignSchema),
);
export type GiftCardCampaignsById = z.infer<typeof giftCardCampaignsById>;

export const giftCardCampaignsByIdResponse = z.object({
  giftCardId: z.string(),
  campaigns: z.array(giftCardCampaignSchema),
});
export type GiftCardCampaignsByIdResponse = z.infer<
  typeof giftCardCampaignsByIdResponse
>;

export const giftCardCampaignsResponseSchema = z.array(
  giftCardCampaignsByIdResponse,
);

export type GiftCardCampaignsResponseSchema = z.infer<
  typeof giftCardCampaignsResponseSchema
>;

export const campaignSchema = z.object({
  id: z.string(),
  status: z.string(),
  title: z.string(),
  description: z.string(),
  coverImageUrl: z.string(),
  termsAndConditions: z.string().nullable(),
  qualifyingEndTime: z.string().nullable(),
  ctaButton: z.boolean(),
  ctaText: z.string().nullable(),
  ctaUrl: z.string().nullable(),
  ranking: z.number(),
  userId: z.string(),
});

export type CampaignSchema = z.infer<typeof campaignSchema>;

export const campaignsResponseSchema = z.object({
  data: z.array(campaignSchema),
  meta: z.object({
    count: z.number(),
  }),
});

export type CampaignsResponseSchema = z.infer<typeof campaignsResponseSchema>;

export const campaignDiscountResponseSchema = z.object({
  points_discount: z.number(),
});

export type CampaignDiscountResponseSchema = z.infer<
  typeof campaignDiscountResponseSchema
>;
