import { DateRangePickerDateFormat } from '@/design-system-components/date-picker/types';
import { useTranslation } from '@/translation/translation';
import {
  DEFAULT_DATE_FORMAT_OPTION,
  DEFAULT_WEEKDAY_FORMAT_OPTION,
} from '@/utils/date-time-format-options';
import { getLocalTimeZone } from '@internationalized/date';
import { DateValue } from 'react-aria-components';
import { useDateTimeFormatter } from './use-date-time-formatter';
import { usePlaceholderMapping } from './use-placeholder-mapping';

function parseDateString(dateString: string): Date {
  const [year, month, day] = dateString.split('-');
  if (!year || !month || !day) {
    return new Date(dateString);
  }

  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

export function useDisplayDate(
  date?: DateValue | string,
  dateFormat: DateRangePickerDateFormat = 'short',
) {
  const { t } = useTranslation();

  const { dateFormatter, weekdayFormatter } = useDateTimeFormatter([
    DEFAULT_DATE_FORMAT_OPTION,
    DEFAULT_WEEKDAY_FORMAT_OPTION,
  ]);

  const mappedPlaceholder = usePlaceholderMapping();

  if (!date) {
    return t(`${mappedPlaceholder}${dateFormat === 'long' ? ' (Day)' : ''}`);
  }

  const dateObj =
    typeof date === 'string'
      ? parseDateString(date)
      : date.toDate?.(getLocalTimeZone());

  return `${dateFormatter.format(dateObj)} ${
    dateFormat === 'long' ? ` (${weekdayFormatter.format(dateObj)})` : ''
  }`;
}
