import { useLocale } from '@/app/auth-store';
import { useTranslations } from 'next-intl';

export function usePlaceholderMapping(separator: string = ' '): string | null {
  const t = useTranslations('datePicker');
  const locale = useLocale();
  const dateParts = new Intl.DateTimeFormat(locale).formatToParts(new Date());

  return dateParts
    .map((part) => {
      switch (part.type) {
        case 'year': {
          return t('yearPlaceholder');
        }
        case 'month': {
          return t('monthPlaceholder');
        }
        case 'day': {
          return t('dayPlaceholder');
        }
        default: {
          return separator;
        }
      }
    })
    .join('');
}
