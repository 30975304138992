import { GiftCardCampaignSchema } from '@/schema/campaigns/campaign.schema';

export const getFeaturedCampaign = (
  eligibleCampaigns: GiftCardCampaignSchema[],
) => {
  if (!eligibleCampaigns || eligibleCampaigns.length === 0) {
    return null;
  }

  return eligibleCampaigns.reduce((highestPriority, current) => {
    return current.ranking < highestPriority.ranking
      ? current
      : highestPriority;
  }, eligibleCampaigns[0]);
};

export const getAppliedDenomination = ({
  appliedDenominations,
  displayValue,
}: {
  appliedDenominations: GiftCardCampaignSchema['appliedDenominations'];
  displayValue: number;
}): GiftCardCampaignSchema['appliedDenominations'][0] | undefined => {
  const specificDenomination = appliedDenominations.find(
    (denomination) => denomination.denomination === displayValue,
  );

  if (specificDenomination) return specificDenomination;

  const generalDenomination = appliedDenominations.find(
    (denomination) => denomination.denomination === -1,
  );

  return generalDenomination;
};
