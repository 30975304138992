'use client';
import { AmplitudeCustomEventProperties } from '@/hooks/amplitude/use-track-amplitude-event';
import { useTrackAmplitudeEventOnPageLoad } from '../../hooks/amplitude/use-track-amplitude-event-on-page-load';

export const AmplitudePageLoadTrackingServerComponentOnly = ({
  pageName,
  eventProperties,
}: {
  pageName: string;
  eventProperties?: AmplitudeCustomEventProperties;
}) => {
  useTrackAmplitudeEventOnPageLoad(pageName, eventProperties);
  return null;
};
