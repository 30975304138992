import { useTranslations } from 'next-intl';
import { ComponentProps, useMemo } from 'react';

import { useDisplayDate } from '@/hooks/utils/use-display-date';
import { GiftCardCampaignSchema } from '@/schema/campaigns/campaign.schema';
import { cn } from '@/utils/tailwind';

import { getFeaturedCampaign } from './utils/gift-card-campaign-utils';

interface CampaignDiscountLabelProps extends ComponentProps<'div'> {
  eligibleCampaigns: GiftCardCampaignSchema[];
  showTimeEnd?: boolean;
}

export function CampaignDiscountLabel(props: CampaignDiscountLabelProps) {
  const {
    eligibleCampaigns,
    className,
    showTimeEnd = true,
    ...otherProps
  } = props;

  const t = useTranslations('campaignDiscountLabel');
  const appliedCampaign = getFeaturedCampaign(eligibleCampaigns);
  const dateFormatted = useDisplayDate(
    appliedCampaign?.qualifyingEndTime ?? undefined,
  );

  // We are assuming that all denominations will have the same discount configuration for now.
  const appliedDenomination = appliedCampaign?.appliedDenominations[0];

  const labelText = useMemo(() => {
    const hasEndTime = Boolean(
      showTimeEnd && appliedCampaign?.qualifyingEndTime,
    );
    switch (appliedDenomination?.discountType) {
      case 'multiplier': {
        const multiplierText = appliedDenomination?.discountPercentage
          ? t('multiplier_hasEndTime_discount_date', {
              hasEndTime,
              discount: appliedDenomination.discountPercentage / 100,
              date: dateFormatted,
            })
          : '';
        return multiplierText;
      }
      case 'lumpsum': {
        const lumpsumText = appliedDenomination?.discountAmount
          ? t('lumpsum_hasEndTime_amount_date', {
              hasEndTime,
              amount: appliedDenomination.discountAmount,
              date: dateFormatted,
            })
          : '';
        return lumpsumText;
      }
      default: {
        return '';
      }
    }
  }, [appliedCampaign, appliedDenomination, dateFormatted, showTimeEnd, t]);

  if (!appliedCampaign || !appliedDenomination) {
    return null;
  }

  return (
    <div
      className={cn(
        'gap-2 rounded bg-primary-200 px-2 text-primary',
        className,
      )}
      {...otherProps}
    >
      {labelText}
    </div>
  );
}
