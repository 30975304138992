'use client';
import { useHermes } from '@/auth/use-hermes';
import { useEffect } from 'react';
import {
  AmplitudeTrackEventFunction,
  useTrackAmplitudeEvent,
} from './use-track-amplitude-event';

export const useTrackAmplitudeEventOnPageLoad: AmplitudeTrackEventFunction = (
  eventName,
  eventProperties,
) => {
  const trackAmplitudeEvent = useTrackAmplitudeEvent();
  const hermes = useHermes();

  useEffect(() => {
    if (!hermes.user?.sub) {
      return;
    }

    trackAmplitudeEvent(eventName, eventProperties);
  }, [hermes.user?.sub]);
};
